<template>
    <div>
        <DeleteModal
            :is-open="state.isDeleteModal"
            :title="'transaction - ' + state.transactionReference"
            :close-modal="() => toggleDeleteModal(false)"
            :delete-transaction="deleteTransaction"
            :disable-delete-button="state.disableDeleteButton"
        />
        <SendReceiptModal :closeModal="() => toggleSendReceiptModal({ status: false, reference: null })" />
        <EditTransactionModal :closeModal="() => toggleEditTransactionModal({ status: false, reference: null })" />

        <template v-if="!transactions?.data">
            <Spinner />
        </template>
        <!-- <div class="scrollmenu w-100 center table-overflow"></div> -->
        <div v-if="transactions?.data?.length">
            <div v-if="transactions?.data" class="scrollmenu w-90 center table-overflow">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="bb b--black-20 tl">
                                <span class="flex items-center">
                                    <span>Number</span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl items-center">
                                <span class="flex items-center">
                                    <span>Date</span>
                                    <span class="header-filter-sort-icon">
                                        <span
                                            v-if="state.isOpen && getTheValue == 'date'"
                                            class="expand sort1"
                                            @click="sortTransactionsTable('asc', 'date')"
                                        >
                                            <img
                                                :src="require('@/assets/images/sort-up.png')"
                                                class="directional-up"
                                                style="height: 8px; width: 7px"
                                                alt=""
                                            />
                                        </span>
                                        <span v-else class="expand sort1" @click="sortTransactionsTable('desc', 'date')">
                                            <img
                                                :src="require('@/assets/images/caret-down.png')"
                                                class="directional-up"
                                                style="height: 8px; width: 7px"
                                                alt=""
                                            />
                                        </span>
                                    </span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl w-10">
                                <span class="flex items-center">
                                    <span>Type</span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl">
                                <span class="flex items-center">
                                    <span>Customer</span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl">
                                <span class="flex items-center">
                                    <span>Description</span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl w-10">
                                <span class="flex items-center">
                                    <span>Point</span>
                                </span>
                            </th>
                            <th class="bb b--black-20 tl">
                                <span class="flex items-center">
                                    <span>Amount</span>
                                    <span class="header-filter-sort-icon">
                                        <span
                                            v-if="state.isOpen && getTheValue == 'amount'"
                                            class="expand sort1"
                                            @click="sortTransactionsTable('asc', 'amount')"
                                        >
                                            <img
                                                :src="require('@/assets/images/sort-up.png')"
                                                class="directional-up"
                                                style="height: 8px; width: 7px"
                                                alt=""
                                            />
                                        </span>
                                        <span v-else class="expand sort1" @click="sortTransactionsTable('desc', 'amount')">
                                            <img
                                                :src="require('@/assets/images/caret-down.png')"
                                                class="directional-up"
                                                style="height: 8px; width: 7px"
                                                alt=""
                                            />
                                        </span>
                                    </span>
                                </span>
                            </th>

                            <th class="bb b--black-20 tl">
                                <span class="flex items-center">
                                    <span> Actions</span>
                                </span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="transaction of transactions?.data" v-bind:key="transaction?.reference">
                            <expense-prepaid
                                v-if="transaction?.expense_type === 'prepaid'"
                                :transaction="transaction"
                                :subTransactions="transaction?.prepaymentData"
                                @on-delete-transaction="onDeleteTransaction"
                                :role="role"
                            />
                            <expense-prepaid
                                v-else-if="transaction?.type === 'asset_buy'"
                                :transaction="transaction"
                                :subTransactions="transaction?.depreciationData"
                                @on-delete-transaction="onDeleteTransaction"
                                :role="role"
                            />
                            <tr v-else class="font-w1" style="cursor: pointer">
                                <td class="pv3 pr3 bb b--black-20 tl font-w2">
                                    <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                                        {{ transaction?.number || transaction?.reference }}
                                    </router-link>
                                </td>
                                <td class="pv3 pr3 bb b--black-20 tl">{{ formatDate(transaction?.date) }}</td>
                                <td class="pv3 pr3 bb b--black-20 tl items-center flex pr5">
                                    <img src="../../../assets/images/file.svg" class="mr1 icon-default mb1" alt="" />
                                    <span class="ttc pa0">{{
                                        transaction?.subtype === 'stock_introduced' &&
                                        transaction?.transactionPoint === 'transfer_in'
                                            ? 'Transfer in'
                                            : transaction?.subtype === 'promotion_stock' &&
                                              transaction?.transactionPoint === 'transfer_out'
                                            ? 'Transfer out'
                                            : transaction?.type === 'outflows' ||
                                              transaction?.type === 'inflows' ||
                                              transaction?.basedOn
                                            ? transaction?.subtype.split('_').join(' ').toString()
                                            : transaction.type === 'settlement'
                                            ? 'Upfront payment'
                                            : transaction.type === 'prepaid_expense'
                                            ? 'Prepaid Expense'
                                            : transaction.type === 'asset_buy'
                                            ? 'Asset Buy'
                                            : transaction.type === 'asset_sale'
                                            ? 'Asset Sale'
                                            : transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'
                                            ? 'Produce'
                                            : transaction?.type === 'repayment' && transaction?.transactionPoint === 'invoice'
                                            ? 'Invoice payment'
                                            : transaction?.type
                                    }}</span>
                                </td>

                                <td class="pv3 pr3 bb b--black-20 tl tooltip font-w2">
                                    <template
                                        v-if="
                                            !transaction?.entityName?.includes('Clientes Varios') &&
                                            !transaction?.entityName?.includes('Unknown')
                                        "
                                    >
                                        <router-link
                                            :to="{ name: 'Entity', params: { id: transaction?.entity } }"
                                            v-if="transaction?.entity && (role === 'owner' || role === 'admin')"
                                        >
                                            <span v-if="transaction?.entityName?.includes('undefined')">
                                                {{ transaction?.entityName?.replace('undefined', '') }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    transaction?.entityName?.length > 14
                                                        ? `${transaction?.entityName?.slice(0, 14)}...`
                                                        : transaction?.entityName || '-'
                                                }}</span
                                            >
                                            <span
                                                v-if="transaction?.entityName?.length > 14"
                                                class="tooltiptext"
                                                style="padding: 10px"
                                                >{{ transaction?.entityName }}</span
                                            >
                                        </router-link>

                                        <span v-else>
                                            {{ transaction?.entityName || '-' }}
                                        </span>
                                    </template>

                                    <template v-else>
                                        <span
                                            v-if="
                                                transaction?.entityName === 'Clientes Varios' ||
                                                transaction?.entityName === 'Unknown'
                                            "
                                            >-</span
                                        >
                                        <template v-else>
                                            <span v-if="transaction?.entityName?.includes('undefined')">
                                                {{ transaction?.entityName?.replace('undefined', '') }}
                                            </span>
                                            <span v-else>{{ transaction?.entityName || '-' }}</span>
                                        </template>
                                    </template>
                                </td>

                                <td class="pv3 pr3 bb b--black-20 desc-width">
                                    <div v-if="transaction?.description">
                                        {{
                                            transaction?.description?.length > 15
                                                ? `${transaction?.description?.slice(0, 15)}...`
                                                : transaction?.description
                                        }}
                                    </div>
                                    <div v-else-if="transaction?.items?.length > 1">
                                        {{
                                            transaction?.items?.[0]?.productName?.slice(0, 20) ||
                                            transaction?.items?.[0]?.name?.slice(0, 20)
                                        }}
                                        +{{ transaction?.items.length - 1 }}
                                        more...
                                    </div>
                                    <div v-else-if="transaction?.products?.length > 1">
                                        {{
                                            transaction?.products?.[0]?.productName?.slice(0, 20) ||
                                            transaction?.products?.[0]?.name?.slice(0, 20)
                                        }}
                                        +{{ transaction?.products.length - 1 }}
                                        more...
                                    </div>
                                    <div v-else-if="transaction?.items?.[0]?.productName">
                                        {{
                                            transaction?.items?.[0]?.productName.length > 20
                                                ? `${transaction?.items?.[0]?.productName?.slice(0, 20)}...`
                                                : transaction?.items?.[0]?.productName
                                        }}
                                    </div>
                                    <div v-else-if="transaction?.items?.[0]?.name">
                                        {{
                                            transaction?.items?.[0]?.name.length > 20
                                                ? `${transaction?.items?.[0]?.name?.slice(0, 20)}...`
                                                : transaction?.items?.[0]?.name || '-'
                                        }}
                                    </div>
                                    <div v-else style="width: 300px">-</div>
                                </td>
                                <td class="pv3 pr3 bb b--black-20 tl w-10">
                                    <span v-if="transaction?.transactionPoint === 'POS'" class="b">
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            title="POS"
                                        >
                                            <span class="badge badge-pill-2 badge-primary">P</span>
                                        </router-link>
                                    </span>
                                    <span
                                        v-else-if="
                                            transaction?.transactionPoint === 'invoice' ||
                                            (transaction?.items?.[0]?.name && transaction?.type === 'sales')
                                        "
                                        class="b"
                                    >
                                        <a href="#" @click.prevent="viewInvoice(transaction?.reference)" title="Invoice">
                                            <span class="badge badge-pill badge-warning">I</span>
                                        </a>
                                    </span>
                                    <span
                                        v-else-if="
                                            transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'
                                        "
                                    >
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            title="Manufacturing/Production"
                                        >
                                            <span class="badge badge-pill-2 badge-dark">M</span>
                                        </router-link>
                                    </span>
                                    <span v-else-if="transaction?.transactionPoint === 'asset'">
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            title="Asset"
                                        >
                                            <span class="badge badge-pill-2 badge-danger">A</span>
                                        </router-link>
                                    </span>
                                    <span v-else-if="transaction?.transactionPoint === 'paystack'" class="b">
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            title="Paystack"
                                        >
                                            <img
                                                src="../../../assets/images/paystack-favicon-32x32.png"
                                                class="icon-default"
                                                alt=""
                                            />
                                        </router-link>
                                    </span>
                                    <span v-else class="b">
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            title="Direct"
                                        >
                                            <span class="badge badge-pill-2 badge-success">D</span>
                                        </router-link>
                                    </span>
                                </td>
                                <td class="pv3 pr3 bb b--black-20 tl">
                                    {{
                                        formatAmount(
                                            transaction?.price || transaction?.amount || transaction?.secondaryAmount,
                                            $store.state.Settings?.currency
                                        )
                                    }}
                                </td>
                                <td
                                    class="flex justify-between items-center pv3 pr3 bb b--black-20 tl"
                                    style="width: max-content"
                                >
                                    <div>
                                        <router-link
                                            :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                            v-tippy="'View receipt'"
                                        >
                                            <img :src="require('@/assets/images/visibilityNew.svg')" class="pr2" alt="" />
                                        </router-link>
                                    </div>
                                    <div v-if="role === 'admin' || role === 'owner' || rolePermissions?.includes(38)">
                                        <router-link
                                            v-if="transaction?.transactionPoint !== 'invoice'"
                                            to="#"
                                            v-tippy="'Delete transaction'"
                                            @click.prevent="toggleDeleteModal(true, transaction?.reference, transaction?.type)"
                                        >
                                            <img :src="require('@/assets/images/delete.svg')" class="pr2" alt="" />
                                        </router-link>
                                    </div>
                                    <div v-if="state.resending">
                                        <font-awesome-icon icon="fas fa-spinner" />
                                    </div>
                                    <div v-else>
                                        <router-link
                                            v-if="transaction.type === 'sales'"
                                            to="#"
                                            v-tippy="'Resend receipt'"
                                            @click.prevent="resendReceipt(transaction?.reference)"
                                        >
                                            <img :src="require('@/assets/images/Reload.svg')" class="pr2" alt="" />
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link
                                            v-if="transaction.type === 'sales'"
                                            to="#"
                                            v-tippy="'Send receipt'"
                                            @click.prevent="
                                                toggleSendReceiptModal({ status: true, reference: transaction?.reference })
                                            "
                                        >
                                            <img :src="require('@/assets/images/mailNew.svg')" class="pr2" alt="" />
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link
                                            to="#"
                                            v-tippy="'Edit Transaction'"
                                            @click.prevent="
                                                toggleEditTransactionModal({ status: true, transaction: transaction })
                                            "
                                        >
                                            <img :src="require('@/assets/images/editNew.svg')" class="pr2" alt="" />
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <Pagination :pages="transactions?.pagination" route-name="Transactions" :onNavigate="onNavigate" />
        </div>
        <TableEmptyState
            v-else
            :data-array="transactions?.data"
            message="No transaction recorded"
            rout-name="AddTransaction"
            route-text="+ Add transaction"
        />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed, reactive, ref } from 'vue'
import Pagination from '@/components/Pagination'
import {
    formatAmount,
    formatDate,
    sortAlphaNumericData,
    sortByNumericData,
    sortByDate,
    getLastSevenDaysDateRange,
} from '@/utils/lib'
import TableEmptyState from '@/components/TableEmptyState'
import Spinner from '@/components/Spinner'
import DeleteModal from '@/components/DeleteModal'
import ExpensePrepaid from './ExpensePrepaid'
import SendReceiptModal from './SendReceiptModal'
import EditTransactionModal from './EditTransactionModal'

export default {
    name: 'TableList',
    components: {
        TableEmptyState,
        Pagination,
        Spinner,
        DeleteModal,
        ExpensePrepaid,
        SendReceiptModal,
        EditTransactionModal,
    },
    props: {
        transactions: Object,
        startDate: String,
        endDate: String,
        onNavigate: Function,
        action: String,
        role: String,
    },
    data: () => ({
        isOpen: true,
    }),

    setup(props) {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const getTheValue = ref('')

        const state = reactive({
            isDeleteModal: false,
            disableDeleteButton: false,
            transactionReference: null,
            transactionType: null,
            isOpen: true,
            resending: false,
        })

        const sortTransactions = (payload, sort) => {
            // eslint-disable-next-line no-param-reassign
            payload.query = {
                page: route.query.page,
                limit: route.query.limit || 25,
                filterType: route.query.filterType,
                startDate: route.query.startDate,
                endDate: route.query.endDate,
                howLongInNumber: route.query.howLongInNumber,
                oneOfDaysWeeksMonthsYears: route.query.oneOfDaysWeeksMonthsYears,
                sort,
            }

            router.push({
                name: 'Transactions',
                query: {
                    page: route.query.page,
                    limit: route.query.limit || 25,
                    filterType: route.query.filterType,
                    startDate: route.query.startDate,
                    endDate: route.query.endDate,
                    howLongInNumber: route.query.howLongInNumber,
                    oneOfDaysWeeksMonthsYears: route.query.oneOfDaysWeeksMonthsYears,
                    sort,
                },
            })

            store.dispatch('Transaction/sortTransactions', payload)
        }

        const onDeleteTransaction = (data) => {
            const { modalState, reference, type } = data
            toggleDeleteModal(modalState, reference, type)
            updateTransactionPage()
        }

        const toggleDeleteModal = (modalState = false, reference = null, type = null) => {
            state.isDeleteModal = modalState
            state.transactionReference = reference
            state.transactionType = type
            state.disableDeleteButton = false
        }

        const resendReceipt = (reference) => {
            // state.resending = true
            store.dispatch('Transaction/resendReceipt', reference).finally(() => {
                state.resending = false
            })
        }

        const toggleSendReceiptModal = (payload) => {
            store.dispatch('Transaction/toggleSendReceiptModal', { ...payload })
        }

        const toggleEditTransactionModal = (payload) => {
            store.dispatch('Transaction/toggleEditTransactionModal', { ...payload })
        }

        const deleteTransaction = () => {
            state.disableDeleteButton = true
            let action = 'Transaction/deleteTransaction';
            if (state.transactionType === 'sales') {
                action = 'Transaction/deleteSalesTransaction';
            }

            if (state.transactionType === 'repayment') {
                action = 'Transaction/deleteSalesRepaymentTransaction';
            }

            store.dispatch(action, state.transactionReference).then(() => {
                toggleDeleteModal()
                updateTransactionPage()
            })
        }

        // Clickable row

        function clickableRow(transactionReference) {
            router.push({ name: 'Transaction', params: { id: transactionReference } })
        }

        const updateTransactionPage = () => {
            const { startDate, endDate } = getLastSevenDaysDateRange(30)

            const query = {
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                sort: route.query.sort || 'date',
                mode: route.query.mode || 'desc',
                filterType: route.query.filterType || 'customrange',
                start: route.query?.start ? new Date(route.query.start) : new Date(props.startDate),
                end: route.query.end ? new Date(route.query.end) : new Date(props.endDate),
            }

            store.dispatch('Transaction/getAllSalesTransactions', query)
            store.dispatch('Transaction/getAllTransactions', query)
            store.dispatch('Transaction/getAllPurchaseTransactions', query)
            store.dispatch('Transaction/getAllExpenseTransactions', query)
            store.dispatch('Transaction/getAllOtherTransactions', query)
            store.dispatch('Transaction/getTransactionSummary', query)

            store.dispatch('Transaction/getSalesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'sales',
            })
            store.dispatch('Transaction/getPurchasesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'purchases',
            })
            store.dispatch('Transaction/getExpensesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'expenses',
            })
        }

        const viewInvoice = (reference) => {
            store.dispatch('Invoice/getInvoiceByReference', reference).then((invoice) => {
                router.push({
                    name: 'Invoice',
                    params: { id: invoice._id },
                })
            })
        }
        const sortTransactionsTableOld = (order, value) => {
            getTheValue.value = value
            const payload = {
                data: props.transactions.data,
                order: order,
                value: value,
            }
            switch (value) {
                case 'amount':
                    sortByNumericData(payload)
                    break
                case 'reference':
                    sortAlphaNumericData(payload)
                    break
                case 'date':
                    sortByDate(payload)
                    break
                default:
                    break
            }
        }

        const sortTransactionsTable = (mode, sortBy) => {
            state.isOpen = !state.isOpen
            getTheValue.value = sortBy
            const query = {
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                sort: route.query.sort || sortBy,
                mode: route.query.mode || mode,
                filterType: route.query.filterType || 'customrange',
                start: route.query?.start ? new Date(route.query.start) : new Date(props.startDate),
                end: route.query.end ? new Date(route.query.end) : new Date(props.endDate),
            }

            store.dispatch('Transaction/getAllSalesTransactions', query)
            store.dispatch('Transaction/getAllTransactions', query)
            store.dispatch('Transaction/getAllPurchaseTransactions', query)
            store.dispatch('Transaction/getAllExpenseTransactions', query)
            store.dispatch('Transaction/getAllOtherTransactions', query)
        }

        return {
            state,
            formatAmount,
            formatDate,
            sortTransactions,
            deleteTransaction,
            toggleDeleteModal,
            onDeleteTransaction,
            viewInvoice,
            sortTransactionsTable,
            rolePermissions,
            getTheValue,
            clickableRow,
            resendReceipt,
            toggleSendReceiptModal,
            toggleEditTransactionModal,
        }
    },
}
</script>

<style>
.scrollmenu {
    overflow: auto !important;
    white-space: nowrap !important;
}
.desc-width {
    width: 10% !important;
}
@media screen and (max-width: 700px) {
    .pr3 {
        padding-right: 3rem;
    }

    img {
        max-width: unset !important;
    }
}
</style>
