<template>
    <div
        v-if="isOpen"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center flex items-center"
        id="add-product-form"
        :class="isOpen && 'jquery-modal blocker current'"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-40-l w-90 pv2 center box-border" style="background: white" ref="deleteModalRef">
            <div class="flex justify-between pa3">
                <div></div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <div class="entity-form tc">
                <div class="tc pa3 pa0-l">
                    <img src="@/assets/images/Delete-img.svg " style="width:50px;" alt="" />
                    <h2 class="lh-title f3-l f5">You are about to delete a transaction!</h2>
                    <p class="lh-copy">
                        Are you sure want to delete this <span class="red ttc">{{ title }}?</span>
                    </p>
                </div>
                <div class="pa3">
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt3" @click.prevent="closeModal">Cancel</button>
                        <button
                            class="mt3 bg-dark-red"
                            type="submit"
                            :disabled="disableDeleteButton"
                            @click.prevent="deleteTransaction"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'DeleteModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        title: String,
        closeModal: Function,
        deleteTransaction: Function,
        disableDeleteButton: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const deleteModalRef = ref(null)

        const handleOnClickOutsideModal = (e) => {
            if (deleteModalRef.value && !deleteModalRef.value.contains(e.target)) {
                props.closeModal()
            }
        }

        return { deleteModalRef, handleOnClickOutsideModal }
    }
}
</script>

<style scoped></style>
