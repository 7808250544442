<template>
    <tr class="font-w1">
        <td class="pv3 pr3 bb b--black-20 tl font-w2">
            <div v-if="transaction?.prepaymentData?.length || transaction?.depreciationData?.length" class="flex items-center">
                <a href="#" @click.prevent="state.active = !state.active">
                    <img v-if="state.active" src="../../../assets/images/arrow-down.svg" alt="" />
                    <img v-else src="../../../assets/images/arrow-right.svg" alt="" />
                </a>
                <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                    {{ transaction?.number || transaction?.reference }}</router-link
                >
            </div>
            <router-link v-else :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                {{ transaction?.number || transaction?.reference }}</router-link
            >
        </td>
        <td class="pv3 pr3 bb b--black-20 tl">{{ formatDate(transaction?.date) }}</td>
        <td class="pv3 pr3 bb b--black-20 tl items-center flex">
            <img src="../../../assets/images/file.svg" class="mr1 icon-default mb1" alt="" />
            <span class="ttc pa0">
                {{ transaction.type === 'prepaid_expense' ? 'Prepaid Expense' : 'Asset Buy' }}
            </span>
        </td>

        <td class="pv3 pr3 bb b--black-20 tl font-w2">
            <template
                v-if="!transaction?.entityName?.includes('Clientes Varios') && !transaction?.entityName?.includes('Unknown')"
            >
                <router-link
                    :to="{ name: 'Entity', params: { id: transaction?.entity } }"
                    v-if="transaction?.entity && (role === 'owner' || role === 'admin')"
                >
                    <span v-if="transaction?.entityName?.includes('undefined')">
                        {{ transaction?.entityName?.replace('undefined', '') }}
                    </span>
                    <span v-else> {{ transaction?.entityName || '-' }}</span>
                </router-link>

                <span v-else>
                    {{ transaction?.entityName || '-' }}
                </span>
            </template>

            <template v-else>
                <span v-if="transaction?.entityName === 'Clientes Varios' || transaction?.entityName === 'Unknown'">-</span>
                <template v-else>
                    <span v-if="transaction?.entityName?.includes('undefined')">
                        {{ transaction?.entityName?.replace('undefined', '') }}
                    </span>
                    <span v-else>{{ transaction?.entityName || '-' }}</span>
                </template>
            </template>
        </td>
        <td v-if="transaction?.subtype" class="pv3 pr3 bb b--black-20 tl">
            {{
                transaction?.subtype
                    .split('_')
                    .join(' ')
                    .toString()
            }}
            for {{ transaction?.duration }} months
        </td>
        <td v-else-if="transaction?.type === 'asset_buy'" class="pv3 pr3 bb b--black-20 tl">
            depreciation for {{ transaction?.depreciationData.length }} years
        </td>
        <td v-else class="pv3 pr3 bb b--black-20 tl">&nbsp;</td>
        <td class="pv3 pr3 bb b--black-20 tl b">
            <router-link
                :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
            >
                <span v-if="transaction?.type === 'asset_buy'" class="badge badge-pill-2 badge-danger" title="Asset">A</span>
                <span v-else class="badge badge-pill-2 badge-success" title="Direct">D</span>
            </router-link>
        </td>
        <td class="pv3 pr3 bb b--black-20 tl">
            {{
                formatAmount(
                    transaction?.price || transaction?.amount || transaction?.secondaryAmount,
                    $store.state.Settings?.currency
                )
            }}
        </td>
        <td class="flex justify-between items-center pv3 pr3 bb b--black-20 tl">
            <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }">
                <img src="../../../assets/images/enable.svg" class="pr2" alt="" />
            </router-link>

            <template v-if="role === 'admin' || role === 'owner'">
                <router-link to="#" @click.prevent="toggleDeleteModal(true, transaction?.reference)">
                    <img src="../../../assets/images/delete.svg" class="pr2" alt="" />
                </router-link>
            </template>
        </td>
    </tr>

    <div>
    <template v-for="(data, index) of subTransactions" :key="index">
        <tr v-if="state.active" class="font-w1">
            <td class="pv3 pr3 pl4 bb b--black-20 tl font-w2">
                <router-link
                    :to="{ name: 'Transaction', params: { id: transaction?.reference }, query: { prepaid: data?.reference } }"
                >
                    {{ data?.reference }}
                </router-link>
            </td>
            <td class="pv3 pr3 bb b--black-20 tl">{{ formatDate(data?.date) }}</td>
            <td class="pv3 pr3 bb b--black-20 tl items-center flex">
                <span v-if="transaction?.type === 'asset_buy'" class="ttc pa0">Depreciation</span>
                <span v-else class="ttc pa0">Expense</span>
            </td>

            <td class="pv3 pr3 bb b--black-20 tl font-w2">
                <template
                    v-if="!transaction?.entityName?.includes('Clientes Varios') && !transaction?.entityName?.includes('Unknown')"
                >
                    <router-link
                        :to="{ name: 'Entity', params: { id: transaction?.entity } }"
                        v-if="transaction?.entity && (role === 'owner' || role === 'admin')"
                    >
                        <span v-if="transaction?.entityName?.includes('undefined')">
                            {{ transaction?.entityName?.replace('undefined', '') }}
                        </span>
                        <span v-else> {{ transaction?.entityName || '-' }}</span>
                    </router-link>

                    <span v-else>
                        {{ transaction?.entityName || '-' }}
                    </span>
                </template>

                <template v-else>
                    <span v-if="transaction?.entityName === 'Clientes Varios' || transaction?.entityName === 'Unknown'">-</span>
                    <template v-else>
                        <span v-if="transaction?.entityName?.includes('undefined')">
                            {{ transaction?.entityName?.replace('undefined', '') }}
                        </span>
                        <span v-else>{{ transaction?.entityName || '-' }}</span>
                    </template>
                </template>
            </td>

            <td v-if="transaction?.subtype" class="pv3 pr3 bb b--black-20 tl">
                {{
                    transaction?.subtype
                        .split('_')
                        .join(' ')
                        .toString()
                }}
                for {{ formatDate(data?.date, 'MMMM') }}
            </td>
            <td v-else-if="transaction?.type === 'asset_buy'" class="pv3 pr3 bb b--black-20 tl">
                value as at {{ formatDate(data?.date, 'MMMM, YYYY') }}
            </td>
            <td v-else class="pv3 pr3 bb b--black-20 tl">&nbsp;</td>
            <td class="pv3 pr3 bb b--black-20 tl b">
                <router-link :to="{ name: 'Transaction', params: { id: transaction?.reference } }" title="Direct">
                    <span class="badge badge-pill-2 badge-success">D</span>
                </router-link>
            </td>
            <td class="pv3 pr3 bb b--black-20 tl">
                {{ formatAmount(data?.amount, $store.state.Settings?.currency) }}
            </td>
            <td class="flex justify-between items-center pv3 pr3 bb b--black-20 tl">
                <router-link
                    :to="{ name: 'Transaction', params: { id: transaction?.reference }, query: { prepaid: data?.reference } }"
                >
                    <img src="../../../assets/images/enable.svg" class="pr2" alt="" />
                </router-link>
            </td>
        </tr>
    </template>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { reactive } from 'vue'
import { formatAmount, formatDate } from '@/utils/lib'

export default {
    name: 'ExpensePrepaid',
    props: {
        transaction: Object,
        subTransactions: Array,
        role: String
    },

    setup(props, { emit }) {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const state = reactive({
            isDeleteModal: false,
            disableDeleteButton: false,
            transactionReference: null,
            active: false
        })

        const toggleDeleteModal = (modalState = false, reference = null) => {
            emit('onDeleteTransaction', { modalState, reference })
        }

        return {
            state,
            formatAmount,
            formatDate,
            toggleDeleteModal
        }
    }
}
</script>
